// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const PERM_INVOCATIONS_GET = 'resultdb.invocations.get';
export const PERM_TEST_EXONERATIONS_LIST = 'resultdb.testExonerations.list';
export const PERM_TEST_RESULTS_LIST = 'resultdb.testResults.list';
export const PERM_TEST_EXONERATIONS_LIST_LIMITED =
  'resultdb.testExonerations.listLimited';
export const PERM_TEST_RESULTS_LIST_LIMITED =
  'resultdb.testResults.listLimited';
