// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { ButtonTypeMap } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

import { OutputBuild } from '@/build/types';

import { CancelBuildButton } from './cancel_build_button';
import { RetryBuildButton } from './retry_build_button';

export interface BuildActionButtonProps
  extends Omit<DefaultComponentProps<ButtonTypeMap>, 'onClick' | 'disabled'> {
  readonly build: OutputBuild;
}

export function BuildActionButton({ build, ...props }: BuildActionButtonProps) {
  return build.endTime ? (
    <RetryBuildButton {...props} build={build} />
  ) : (
    <CancelBuildButton {...props} build={build} />
  );
}
